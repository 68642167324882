<style lang="scss">

</style>
<!--eslint-disable-->
<template>
  <div v-if="$can('Read', 'Device')">
    <b-card class="mb-0 " no-body>
      <p class="font-weight-bolder px-1 pt-1">Device Data History</p>
      <section>
        <b-table
          class="data-table"
          hover
          responsive
          :items="records"
          :fields="headers"
          :current-page="currentPage"
          :per-page="0"
        >
          <template #cell(last_policy_sync_time)="data">
            {{data.item.last_policy_sync_time | formatDateTime}}
          </template>
          <template #cell(publish_time)="data">
            {{data.item.publish_time | formatDateTime}}
          </template>

          <template #cell(status)="data">
            <div v-b-tooltip.hover="data.item.status_change_message" class="multiline-ellipsis">
              {{ data.item.status }}
            </div>
          </template>

          <template #cell(action)="data">
            <b-button variant="primary" @click="showModal(data.item, 'modal-history-details')">View</b-button>
          </template>
        </b-table>
        <b-row>
          <b-col md="6" class="my-1" v-if="total_count > filters.page_size">
            <b-pagination @change="getMoreRecords" :total-rows="total_count" :per-page="filters.page_size" v-model="currentPage" class="my-0"/>
          </b-col>
          <b-col class="p-2 pl-3" v-if="total_count === 0">No records found</b-col>
        </b-row>
      </section>
    </b-card>

    <b-modal centered id="modal-history-details" title="History Details" size="xl" hide-footer>
      <device-detail @close="closeModals()" v-if="selectedRecord" :modal="true" :historical-data="selectedRecord" :device="device"></device-detail>
    </b-modal>
  </div>
</template>
<!--eslint-enable-->

<script>
import DeviceService from '@/services/DeviceService';
import DeviceDetail from '@/views/devices/viewDevice/sections/dataHistory/DeviceDetails.vue';
import AdminDeviceService from "@/services/AdminDeviceService";

export default {
  name: 'DeviceDataHistory',
  components: {
    DeviceDetail: DeviceDetail,
  },
  props: {
    device: {
      required: true,
      type: Object,
    },
    isAdminPage: {
      required: true,
      type: Boolean
    },
  },
  data() {
    return {
      selectedRecord: null,
      records: [],
      headers: [
        {
          key: 'publish_time',
          label: 'Publish time',
        },
        {
          key: 'applied_policy_version',
          label: 'Policy',
        },
        {
          key: 'status',
          label: 'Status',
        },
        {
          key: 'is_device_secure',
          label: 'Device Secure',
        },
        {
          key: 'is_encrypted',
          label: 'Encrypted',
        },
        {
          key: 'api_level',
          label: 'Api Level',
        },
        {
          key: 'policy_compliant',
          label: 'Policy Compliant',
        },

        {
          key: 'action',
          label: '',
        },
      ],
      currentPage: 1,
      filters: {
        page: 0,
        page_size: 10,
      },
      total_count: 0,
    };
  },
  mounted() {
    this.getHistoryRecords();
  },
  methods: {
    getMoreRecords(val) {
      this.filters.page = val - 1; // api index starts at 0, bootstrap starts at 1 lol
      this.getHistoryRecords();
    },
    getHistoryRecords() {
      let getRecordsPromise;

      if (this.isAdminPage) {
        getRecordsPromise = AdminDeviceService.getDeviceDataHistoryRecords(this.device.uid, this.filters)
      } else {
        getRecordsPromise = DeviceService.getDeviceDataHistoryRecords(this.device.uid, this.filters)
      }

      getRecordsPromise.then(res => {
        this.records = res.data.data;
        this.filters.page = res.data.meta.page;
        this.filters.page_size = res.data.meta.page_size;
        this.total_count = res.data.meta.total_count;
      }).catch(err => {
        const res = err.response
        let errorText = 'Could not get device history records, please refresh and try again'

        if (res && res.data.error) {
          errorText = res.data.error
        }

        this.$toast.error(errorText, {
          toastClassName: ['toast-std', 'warning-toast'],
        })
      });
    },
    showModal(record, modalName) {
      this.setSelectedRecord(record);
      this.$bvModal.show(modalName);
    },
    setSelectedRecord(record) {
      this.selectedRecord = record;
    },
    closeModals() {
      this.$bvModal.hide('modal-history-details');
    },
  },
};
</script>
